<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('BRANCHES.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyBranchesIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <entity-form :entity="entity" />
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/branchesRepository'
import { DateFormat, Plain } from '@/helpers/normalizers'
import KTCard from '@/view/content/Card'
import EntityForm from '@/components/company/branches/Form'

export default {
  name: 'CompanyBranchesEdit',
  components: {
    EntityForm,
    KTCard,
  },
  data() {
    return {
      loading: false,
      entity: null,
      normalizers: {
        uuid: Plain(),
        name: Plain(),
        address: Plain(),
        phone: Plain(),
        scheduleStartTime: DateFormat('HH:mm:ss'),
        scheduleEndTime: DateFormat('HH:mm:ss'),
        active: Plain(),
      },
    }
  },
  mounted() {
    this.loading = true
    Repo.find(this.$route.params.id)
      .then((entity) => {
        entity.isNew()
        this.entity = entity
        this.loading = false
      })
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('BRANCHES.TITLE_EDIT') }])
  },
}
</script>

<style scoped>

</style>
